<template>
  <div class="home">

    <div class="page-container">

      <div class="info-container">
        <div class="info-content">
          Vous avez debloquer <span class="bold">{{ claimedAchievement.length }}</span> achievements sur un total de <span class="bold">{{ success.length }}</span>
        </div>

        <div class="info-content fake">
          Vous avez debloquer <span class="bold">{{ claimedAchievement.length }}</span> achievements sur un total de <span class="bold">{{ success.length }}</span>
        </div>

        <i class="fad fa-info"></i>
      </div>

      <span class="title-header">
        Quetes
      </span>

      <div class="quest-container" :class="{disable: quest.disable}" v-for="quest in questsGeneral" :key="quest.name">

        <div class="disable-container" v-if="quest.disable">Bientot</div>

        <div class="quest-header">

          <div class="icon-container" :style="quest.color">
            <div class="class-icon"></div>
            <i :class="quest.icon"></i>
          </div>

          <div class="quest-title">{{ quest.name }}</div>

          <div class="claim-container">
            <div class="claim-button" :class="{ active: claimAvailable(quest) }">
              <div v-if="quest.waiting" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              <span v-else>Récupérer</span>
            </div>
          </div>

        </div>

        <div class="quest-content">

          <div class="info-popup" v-if="quest.popup">
            {{ quest.popup }}
          </div>

          <div class="success-container" :class="{ complete: hasSuccess(objectif) }" v-for="objectif in quest.objectifs" :key="objectif.title">

            <div class="icon-container" :style="objectif.color">
              <div v-if="objectif.class" :class="objectif.class" class="class-icon"></div>
              <i v-if="objectif.icon" :class="objectif.icon"></i>
            </div>

            <div class="success-content">

              <div class="title">{{ objectif.description }}</div>

            </div>

            <div class="success-objectif" v-if="objectif.require">
              <div class="actual">{{ valueFormater(getStat(objectif.require.field), objectif.require.type) }}</div>
              <span>/</span>
              <div class="objectif">{{ valueFormater(objectif.require.value, objectif.require.type) }}</div>          
            </div>

            <div class="success-objectif" v-else>
              <div class="objectif"></div>          
            </div>

          </div>

        </div>

        <div v-if="quest.footer" class="quest-footer">

          <div class="footer-content">
            Le nombre de badge est limité a 10 par mois. Je peux a tout moment decider de retirer un badge si je le souhaite et sans aucune justification (Ex: si vous preferez l'été alors que l'hiver c'est mieux) 
          </div>

        </div>

      </div>

      <span class="title-header">
        Quetes Éphémère
      </span>

      <!-- <quest v-for="quest in questsOrdered" :key="quest.publicId" :quest="quest" /> -->

      <div class="quest-container" :class="{disable: quest.disable, completed: questIsCompledted(quest) }" v-for="quest in questsOrdered" :key="quest.publicId">

        <div class="disable-container" v-if="quest.disable">Bientot</div>

        <div class="quest-header">

          <div class="icon-container" :style="quest.color">
            <div class="class-icon"></div>
            <i :class="quest.icon"></i>
          </div>

          <div class="quest-title">{{ quest.name }}</div>
          <span v-if="!quest.startAt || moment().diff(quest.startAt.seconds * 1000) > 0" class="reward">Récompense : {{ quest.reward }} Boules </span>

          <div class="claim-container" v-if="!questIsCompledted(quest) && (!quest.startAt || moment().diff(quest.startAt.seconds * 1000) > 0)">
            <div class="claim-button" :class="{ active: claimAvailable(quest, findQuestStatistique(quest)) && !quest.waiting }"  @click="claimQuest(quest)">
              <div v-if="quest.waiting" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              <span v-else>Récupérer</span>
            </div>
          </div>

        </div>

        <div class="quest-content" v-if="!quest.startAt || moment().diff(quest.startAt.seconds * 1000) > 0">


          <div class="info-popup reward" v-if="quest.reward">
            Récompense : {{ quest.reward }} Boules
          </div>

          <div class="info-popup" v-if="quest.expireAt && !questIsCompledted(quest)">
            Expire {{ moment(quest.expireAt.seconds * 1000).fromNow() }}
          </div>

          <div class="info-popup" v-if="quest.popup">
            {{ quest.popup }}
          </div>

          <div class="success-container" :class="{ complete: hasSuccess(objectif, findQuestStatistique(quest)) && !questIsCompledted(quest) }" v-for="objectif in quest.objectifs" :key="objectif.title">

            <div class="icon-container" :style="objectif.color">
              <div v-if="objectif.class" :class="objectif.class" class="class-icon"></div>
              <i v-if="objectif.icon" :class="objectif.icon"></i>
            </div>

            <div class="success-content">

              <div class="title">{{ objectif.description }}</div>

            </div>

            <div class="success-objectif" v-if="objectif.require">
              <div class="actual">{{ valueFormater(getStat(objectif.require.field, findQuestStatistique(quest)), objectif.require.type) }}</div>
              <span>/</span>
              <div class="objectif">{{ valueFormater(objectif.require.value, objectif.require.type) }}</div>          
            </div>

            <div class="success-objectif" v-else>
              <div class="objectif"></div>          
            </div>

          </div>

          <div class="claim-container" v-if="!questIsCompledted(quest)">
            <div class="claim-button" :class="{ active: claimAvailable(quest) && !quest.waiting }"  @click="claimQuest(quest)">
              Récupérer
            </div>
          </div>

        </div>

        <div class="quest-content" v-else>
          <div class="info-popup" v-if="quest.startAt">
            Commence {{ moment(quest.startAt.seconds * 1000).fromNow() }}
          </div>
        </div>

        <div v-if="quest.footer" class="quest-footer">

          <div class="footer-content">
            Le nombre de badge est limité a 10 par mois. Je peux a tout moment decider de retirer un badge si je le souhaite et sans aucune justification (Ex: si vous preferez l'été alors que l'hiver c'est mieux) 
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { auth, db, firebase } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment-timezone'


import quests  from './quests'

moment.locale('fr');

const collectionRef = db.collection('quests');

const  runDelete = async _ => {

  const snapshot = await collectionRef.get()

  // snapshot.docs.forEach( async doc => {
  //   await doc.ref.delete()
  // })

  quests.forEach((x, i) => {
    db.collection('quests').add({
      ...x
    })
  })
}

// runDelete()

function Last30Days () {
    var result = [];
    for (var i=0; i<30; i++) {
        var d = moment().toDate();
        d.setDate(d.getDate() - i);
        result.push(moment(d).format('YYYY-MM-DD'))
    }

    return result;
}

export default {
  name: 'QuestPage',

  metaInfo () {
    return {
      title: `Quetes - Alxios`
    }
  },

  async created() {
    const users = await db.collection('users').where('userId', '==', this.user.id).get()

    const last30days = Last30Days()

    const statRef = await users.docs[0].ref.collection('statistics-daily')
      .where(firebase.firestore.FieldPath.documentId(), 'in', last30days.slice(0, 10))
    const statRef2 = await users.docs[0].ref.collection('statistics-daily')
      .where(firebase.firestore.FieldPath.documentId(), 'in', last30days.slice(10, 20))
    const statRef3 = await users.docs[0].ref.collection('statistics-daily')
      .where(firebase.firestore.FieldPath.documentId(), 'in', last30days.slice(20, 30))

    const achievementRef = await users.docs[0].ref.collection('achievements')
    const completedQuestsRef = await users.docs[0].ref.collection('completedQuests')

    this.$bind('stats1', statRef)
    this.$bind('stats2', statRef2)
    this.$bind('stats3', statRef3)
    this.$bind('achievement', achievementRef)
    this.$bind('completedQuests', completedQuestsRef)
  },

  firestore () {

    const startOfDay = moment().toDate()

    return {
      success: db.collection('achievements'),
      quests: db.collection('quests').orderBy('expireAt').where('expireAt', '>', startOfDay)
    }
  },

  watch: {
    async quests (quests) {
      const users = await db.collection('users').where('userId', '==', this.user.id).get()

      quests.forEach(async quest => {
        const date = new Date(quest.date.seconds * 1000)
        let key = `daily${date.getDate()}`

        console.log('load ', key)

        let dailyRef = users.docs[0].ref.collection('statistics-daily').doc(moment(date).format('YYYY-MM-DD'))
        this.$bind(key, dailyRef)
      })
    }
  },

  data () {
    return {
      users: [],
      moment,

      quests: [],

      completedQuests: [],

      questsGeneral: [
        {
          name: 'Voie du VIP',
          icon: 'fad fa-gem',

          color: {
            "background-color": "#FBAB7E",
            "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
          },

          footer: true,
          popup: 'Objectif calculer sur les 30 derniers jours',

          type: 'daily',

          objectifs: [
            {
              icon: 'fad fa-clock',
              description: "Atteignez un total de 35 heures passer sur le stream",
              color: {
                "background-color": "#8BC6EC",
                "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
              },
              require: {
                field: 'viewtime',
                value: 60 * 35,
                type: 'hour'
              }
            },
            {
              icon: 'fad fa-album-collection',
              description: "Assistez a 5 stream dans des categories differentes",
              color: {
                "background-color": "#8BC6EC",
                "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
              },
              require: {
                field: 'categories',
                value: 5,
              }
            }
          ]
        },

      ],

      categories: [
        { key: 'noel2021', name: 'Noël 2021 🎅' },
        { key: 'new', name: 'Nouveau' },
        { key: 'social', name: 'Social' },
      ],

      success: [],

      stats1: [],
      stats2: [],
      stats3: [],

      daily3: {},
      daily7: {},
      daily8: {},
      daily9: {},

      achievement: [],
    }
  },

  computed: {
    ...mapState(['user']),

    questsOrdered () {
      return this.quests.map(x => x).sort((a, b) => {
        if (a.startAt) return b.order - a.order
        return -1
      })
    },

    stats () {
      const allData = [...this.stats1, ...this.stats2, ...this.stats3]

      return allData.reduce((current, value) => {
        Object.keys(value).forEach(x => {
          if (typeof value[x] == 'object') {
            if (!current[x]) current[x] = {}
            Object.assign(current[x], value[x])
          }
          else if (current[x]) {
            current[x] += value[x]
          } else {
            current[x] = value[x]
          }
        })
        return current
      }, {})
    },

    claimedAchievement () {
      return this.success.filter(x => {
        if (x.require) {
          return this.getStat(x.require.field) >= x.require.value
        }
        return this.achievement.find(y => y.key == x.key) ? true : false
      }).sort((a, b ) => a.publicId - b.publicId)
    },

    allSuccess() {
      const lasts = {}

      return this.success.filter(x => {
        return this.claimedAchievement.find(y => y.publicId == x.publicId) ? true : true
      }).sort((a, b ) => a.publicId - b.publicId)
        .sort((a, b ) => {

          if (a.require && (!lasts[a.title] || lasts[a.title] == a.level)) {
            lasts[a.title] = a.level

            if (b.require) {
              return this.percentage(b.require) - this.percentage(a.require)
            }
            return -1
          } else {
            return 1
          }

        })
    },

    currentAchievement () {

      const lasts = {}

      return this.success.filter(x => {
        return this.claimedAchievement.find(y => y.publicId == x.publicId) ? false : true
      }).sort((a, b ) => a.publicId - b.publicId)
        .sort((a, b ) => {

          if (a.require && (!lasts[a.title] || lasts[a.title] == a.level)) {
            lasts[a.title] = a.level

            if (b.require) {
              return this.percentage(b.require) - this.percentage(a.require)
            }
            return -1
          } else {
            return 1
          }

        })
      
    }

  },

  methods: {

    findQuestStatistique (quest) {
      return this[`daily${(new Date(quest.date.seconds * 1000)).getDate()}`]
    },

    questIsCompledted (quest) {
      return this.completedQuests.find(x => x.questId == quest.publicId)
    },

    async claimQuest(quest) {
      if (quest.waiting) return

      const access_token = localStorage.getItem('access_token')

      quest.waiting = true

      await axios.post('https://us-central1-alxios-christmas.cloudfunctions.net/completQuest', {
        questId: quest.publicId,
        access_token,
      })

      quest.waiting = false
    },

    claimAvailable (quest, stats = this.stats) {
      return quest.objectifs.every(x => this.hasSuccess(x, stats))
    },

    hasSuccess (success, stats = this.stats) {
      if (success.require) {
        return this.getStat(success.require.field, stats) >= success.require.value
      }
      return this.achievement.find(y => y.key == success.key) ? true : false
    },

    getAchivementData ({ key }) {
      const achievement = this.achievement.find(x => x.key == key)
      return moment(achievement.createdAt.seconds * 1000).calendar('DD/MM/YYYY')
    },

    getStat(key, stats = this.stats) {
      if (!stats) return 0

      const value = stats[key]
      if (!value) return 0
      if (typeof value == 'object') return Object.keys(value).length
      return Math.round(Number(value))
    },

    valueFormater (value, type, sufix = true) {
      if (type == 'hour') {
        return Number(value / 60).toFixed(2).replace('.', ',').replace(',00', '') + ' h'
      } 
      else {
        if (value >= 1_000) return Number(value / 1_000).toFixed(2).replace('.', ',').replace(',00', '') + ' k'
        return value
      }
    },

    percentage(require) {
      if (!require) return 0

      const { field, value } = require
      const current = this.getStat(field)
      const percentage = (current / value) * 100
      return percentage > 100 ? 100 : percentage
    }

  },
  
}

</script>

<style lang="scss" scoped>

.lds-ellipsis {
  left: -33px;
  position: absolute;
  height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  margin-left: 55px;
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.claim-container {
  position: absolute;
  right: 0;

  margin: 0 12px;

  .claim-button {
    padding: 18px 24px;
    border-radius: 5px;

    opacity: 0.5;

    background: #212121;

    font-weight: 500;

    width: 117px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.active {
      opacity: 1;
      background: rgb(127, 199, 127);
      color: white;

      transition: .3s ease all;

      &:hover {
        background: rgb(97, 165, 97);
      }
    }
  }
}

.quest-footer {
  background: #212121;
  border-radius: 0px 0px 5px 5px;

  bottom: 0;
  right: 0;
  left: 0;
  padding: 12px 18px;

  width: 100%;

  line-height: 18px;
  font-weight: 300;
}
.quest-content {
  padding: 8px;

  width: 100%;

  margin-bottom: 8px;

  .claim-container {
    display: none;
    position: relative;
    margin: 0;
    margin-top: 12px;
  }

  .info-popup {
    padding: 12px 18px;

    font-size: 15px;
    font-weight: 500;

    border-radius: 5px;

    margin: 8px 0;
    margin-bottom: 16px;

    background: #212121;

    &.reward {
      background: #90ade6;
      color: white;
      display: none;
    }
  }

}
.quest-container {
  display: flex;
  flex-direction: column;

  &.completed {
    background: #5ab275;
    color: white;

    .success-container {
      background: #7fc77f;

      .icon-container, svg {
        color: white !important;
      }

      .title, .actual, .success-objectif {
        color: white !important;
      }
    }

    .quest-header .icon-container {
      background-color: rgb(82, 193, 119) !important;
      background-image: linear-gradient(135deg, rgb(189, 195, 163) 0%, rgb(73, 195, 111) 100%) !important;
      svg {
        color:white !important;
      }
    }
  }

  

  .disable-container { 
    position: absolute;
    background: rgba(0, 0, 0, 0.78);
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: white;
  }

  &.disable {
  }

  position: relative;
  background: #171717;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 12px 0;
  overflow: hidden;

  margin-bottom: 24px;
  box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.46);

  transition: .3s ease all;

  .icon-container {
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 64px;
    width: 64px;
    border-radius: 5px;
    margin-right: 16px;

    position: relative;

    .class-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      background-size: contain;
    }
    &.disable {
      opacity: 0.3;
    }

    svg {
      color: white;
      width: 42px;
      height: 42px;
    }
  }

  cursor: pointer;

  width: 100%;
}
.quest-header {
  padding: 8px;

  width: 100%;

  border-bottom: 1px solid #373737;
  padding-bottom: 8px;

  align-items: center;

  display: flex;
  flex-direction: row;

  .reward {
    background: #7fc77f;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 3px;
    margin-left: 18px;
    letter-spacing: 0.6px;
  }

  .quest-title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 1.4px;
    font-family: 'Poppins';

    color: white;
  }
}
.info-container {
  position: relative;

  padding: 18px 24px;
  border-radius: 10px;
  color: white;
  margin-bottom: 42px;
  font-size: 15px;
  line-height: 18px;

  overflow: hidden;

  background-color: rgb(139, 198, 236);
  background-image: linear-gradient(135deg, rgb(139, 198, 236) 0%, rgb(149, 153, 226) 100%);

  box-shadow: #00000033 0px 2px 8px 0px;

  &::after {
    color: rgb(139, 198, 236);
    position: absolute;
    top: -2px;
    font-weight: 700;
    letter-spacing: 2px;
    left: 24px;
    text-transform: uppercase;
    background: #1e1e1e;
    border: 2px solid rgb(139, 198, 236);
    padding: 4px 8px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    content: 'Information';
  }

  svg {
    position: absolute;
    top: 0;
    left: 49.5%;
    height: 100%;
    width: 100%;
    color: rgb(139, 198, 236);
    z-index: 0;
  }

  .info-content {
    margin-top: 22px;

    z-index: 1;
    opacity: 0;

    line-height: 20px;

    text-shadow: 1px 1px 6px #00000059;

    .bold {
      font-weight: 700;
      font-size: 16px;
      margin: 0 2px;
    }

    &.fake {
      opacity: 1;
      position: absolute;
      top: 18px;
      margin-right: 24px;
    }
  }
}


.success-container {
  position: relative;
  background: #171717;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 6px 0;

  transition: .3s ease all;

  cursor: pointer;

  &.complete {

    color: #6fc88a;
    .icon-container svg, .title, .actual  {
      color: #6fc88a !important;
      font-weight: 700;
    }

  }

  .success-progress {
    position: absolute;

    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;

    background: #7283ac;
  }

  .success-content {
    overflow: hidden;
    text-overflow: ellipsis;

    height: 48px;
    display: flex;
    margin: 0 4px;
    align-items: center;
    width: 100%;
  }

  .success-objectif {
    display: flex;
    align-items: baseline;
    margin-left: 24px;

    margin-right: 12px;

    .actual {
      white-space: nowrap;
      color: white;
    }

    span {
      margin: 0 4px;
    }

    .objectif {
      white-space: nowrap;
      font-size: 13px;
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 41px;
    width: 41px;
    border-radius: 3px;
    margin-right: 8px;
    margin-left: 8px;

    position: relative;

    .class-icon {
      opacity: 1;
      background-size: contain;
    }
    &.disable {
      opacity: 0.3;
    }

    svg {
      color: white;
      width: 21px;
      height: 21px;
    }
  }

  .title {
    color: white;
    letter-spacing: .6px;
    font-weight: 500;
  }

  .description {
    white-space: nowrap;
    margin-top: 4px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.message-info {
  width: 100%;
  text-align: center;

  margin-top: 24px;

  background: #202225;
  padding: 14px 18px;
  border-radius: 5px;

  svg {
    margin-right: 8px;
  }
}

@media screen and (max-width: 900px) {

  .quest-content {
    .claim-container {
      display: block !important;
    }
  }

  .quest-header .claim-container {
    display: none;
  }

  .info-popup.reward {
    display: block !important;
  }


  .quest-header .reward {
    display: none;
  }

  .title-header {
    align-items: flex-start !important;
    text-align: left !important;
    flex-direction: column !important;

    .title-count {
      margin-top: 12px;
    }
  }

  .home {
    max-height: calc(100vh) !important;
    padding-bottom: 84px;
  }

  .request {
    flex-direction: column !important;
    height: unset !important;

    div {
      margin: 0 !important;
      text-align: left !important;
      width: 100% !important;
      height: 24px;

      margin-left: 24px !important;
    }

    .title-id {
      margin-top: 8px !important;
      margin-bottom: 4px !important;
      display: block !important;
    }

    .price {
      margin-bottom: 4px !important;
    }

    .title-name {
      display: none !important;
    }

  }
}

.title-id {
  display: none !important;
}

.requests-list {
  margin-top: 42px;
}

.request {
  height: 84px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 12px;

  background: #37393e;

  border-radius: 5px;

  overflow: hidden;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .title-name {
    margin-left: 24px;
    margin-right: 24px;

    width: 15%;
    color: white;
  }

  .buyat {
    font-weight: 300;

    width: 25%;

    b {
      color: white;
      text-transform: lowercase;
      margin-left: 6px;
    }
  }

  .price {
    text-align: right;
    width: 55%;

    b {
      color: white;
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .status {
    width: 30%;
    padding-right: 32px;
    text-align: right;
  }

  .img {
    width: 84px;
    height: 84px;

    background-position: center;
    background-size: cover;

    border-right: #202225 1px solid;
    margin-right: 18px;
  }

}

.home {
  max-height: calc(100vh);
  height: 100%;
  overflow: auto;
}

.title-header {
  text-transform: uppercase;
  letter-spacing: 1.2px;

  margin-top: 32px;
  margin-bottom: 24px;

  color: white;

  display: flex;
  align-items: center;

  .title-count {
    color: #B9BBBE;

    margin-left: 4px;
    font-size: 12px;

    margin-bottom: -2px;
  }
}

.page-container {
  max-width: 1500px;
  margin: 0 auto;

  padding-top: 32px;
  padding-bottom: 32px;
}

@media screen and (max-width: 1500px) {
  .page-container {
      max-width: 1225px;
  }
}

@media screen and (max-width: 1225px) {
  .page-container {
      max-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .page-container {
      max-width: 610px;
  }
}


@media screen and (max-width: 780px) {
  .page-container {
      max-width: 610px;
  }
}

</style>

