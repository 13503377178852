import moment from 'moment-timezone'

export default [

  // 03 Decembre

  // {
  //   publicId: '98901ec7-3c15-43c3-b175-163b80bb17ad2',

  //   waiting: false,

  //   name: 'Quêtes Bonus 03',
  //   icon: 'fad fa-rocket',

  //   date: moment('2021-12-03 00:00:00').toDate(),
  //   expireAt: moment('2021-12-08 00:00:00').toDate(),

  //   order: 1,

  //   reward: 250,

  //   disable: true,

  //   color: {
  //     "background-color": "#8BC6EC",
  //     "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
  //   },

  //   footer: false,

  //   objectifs: [
  //     {
  //       key: 'bet',
  //       icon: 'fad fa-dice',
  //       description: "Parier 500 Boule de Noël",
  //       require: {
  //         field: 'betQuantity',
  //         value: 500,
  //       }
  //     }
  //   ]
  // },

  // {
  //   publicId: 'a2803f3c-7685-4317-88c5-476a5dce98c1',
    
  //   order: 2,

  //   name: 'Quêtes journalières 03',
  //   icon: 'fad fa-calendar-alt',

  //   date: moment('2021-12-03 00:00:00').toDate(),
  //   expireAt: moment('2021-12-08 00:00:00').toDate(),
  //   // startAt: moment('2021-12-03 00:00:00').toDate(),

  //   waiting: false,
  //   disable: false,
    
  //   reward: 400,

  //   color: {
  //     "background-color": "#8BC6EC",
  //     "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
  //   },

  //   footer: false,

  //   objectifs: [
  //     {
  //       icon: 'fad fa-coin',
  //       description: "Gagner un total de 250 Boules de Noël",
  //       require: {
  //         field: 'boule2noel',
  //         value: 250,
  //       }
  //     },
  //     {
  //       icon: 'fad fa-cookie',
  //       description: "Offrez deux cookie",
  //       require: {
  //         field: 'cookieSend',
  //         value: 2,
  //       }
  //     },
  //     {
  //       icon: 'fad fa-play',
  //       description: "Participez a l'event de Noël",
  //       require: {
  //         field: 'play',
  //         value: 1,
  //       }
  //     }
  //   ]
  // },

  // // 07 Decembre

  // {
  //   publicId: '2be688aa-3c06-46b5-9e23-8984e85070c3',
    
  //   order: 3,

  //   name: 'Quêtes journalières 07',
  //   icon: 'fad fa-calendar-alt',

  //   date: moment('2021-12-07 00:00:00').toDate(),
  //   expireAt: moment('2021-12-08 00:00:00').toDate(),

  //   waiting: false,
  //   disable: false,
    
  //   reward: 400,

  //   color: {
  //     "background-color": "#8BC6EC",
  //     "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
  //   },

  //   footer: false,

  //   objectifs: [
  //     {
  //       icon: 'fad fa-clock',
  //       description: "Passer un total de 60 minutes sur le stream",
  //       require: {
  //         field: 'viewtime',
  //         value: 60,
  //       }
  //     },
  //     {
  //       icon: 'fad fa-gifts',
  //       description: "Offrir un totale de 250 boules de noel",
  //       require: {
  //         field: 'giftAmount',
  //         value: 250,
  //       }
  //     },
  //     {
  //       icon: 'fad fa-dice',
  //       description: "Parrier vos boules 2 fois (!bet)",
  //       require: {
  //         field: 'bets',
  //         value: 2,
  //       }
  //     },
  //     {
  //       icon: 'fad fa-play',
  //       description: "Participez a l'event de Noël",
  //       require: {
  //         field: 'play',
  //         value: 1,
  //       }
  //     }
  //   ]
  // },

  // // 8 Decembre

  // {
  //   publicId: 'efe5adf7-a2d2-4037-95ca-4c2d96cb90ff',
    
  //   order: 4,

  //   name: 'Quêtes Bonus 08',
  //   icon: 'fad fa-rocket',

  //   date: moment('2021-12-08 00:00:00').toDate(),
  //   expireAt: moment('2021-12-09 00:00:00').toDate(),
  //   startAt: moment('2021-12-08 00:00:00').toDate(),

  //   waiting: false,
  //   disable: false,
    
  //   reward: 500,

  //   color: {
  //     "background-color": "#8BC6EC",
  //     "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
  //   },

  //   footer: false,

  //   objectifs: [
  //     {
  //       icon: 'fad fa-coin',
  //       description: "Perdre un total de 250 boules (!bet)",
  //       require: {
  //         field: 'betLostQuantity',
  //         value: 250,
  //       }
  //     }
  //   ]
  // },

  // {
  //   publicId: 'aaaa9c43-64da-4f5d-8e58-2d8f40ce4930',
    
  //   order: 4,

  //   name: 'Quêtes journalières 08',
  //   icon: 'fad fa-calendar-alt',

  //   date: moment('2021-12-08 00:00:00').toDate(),
  //   expireAt: moment('2021-12-09 00:00:00').toDate(),
  //   startAt: moment('2021-12-08 00:00:00').toDate(),

  //   waiting: false,
  //   disable: false,
    
  //   reward: 400,

  //   color: {
  //     "background-color": "#8BC6EC",
  //     "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
  //   },

  //   footer: false,

  //   objectifs: [
  //     {
  //       icon: 'fad fa-coin',
  //       description: "Gagner un total de 250 Boules de Noël",
  //       require: {
  //         field: 'boule2noel',
  //         value: 250,
  //       }
  //     },
  //     {
  //       icon: 'fad fa-heart',
  //       description: "Utilisez les emotes de la chaine",
  //       require: {
  //         field: 'channelEmotes',
  //         value: 15,
  //       }
  //     },
  //     {
  //       icon: 'fad fa-play',
  //       description: "Participez a l'event de Noël",
  //       require: {
  //         field: 'play',
  //         value: 1,
  //       }
  //     }
  //   ]
  // },

  {
      publicId: '9bbbf4a4-6533-4a9c-82b8-0bb4936ffc16',
      
      order: 1,
  
      name: 'Quêtes journalières 09',
      icon: 'fad fa-calendar-alt',
  
      date: moment('2021-12-09 00:00:00').toDate(),
      expireAt: moment('2021-12-10 00:00:00').toDate(),
      startAt: moment('2021-12-08 00:00:00').toDate(),
  
      waiting: false,
      disable: false,
      
      reward: 400,
  
      color: {
        "background-color": "#8BC6EC",
        "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
      },
  
      footer: false,
  
      objectifs: [
        {
          icon: 'fad fa-clock',
          description: "Passer un total de 40 minutes sur le stream",
          require: {
            field: 'viewtime',
            value: 40,
          }
        },
        {
          icon: 'fad fa-gifts',
          description: "Offrir un totale de 200 boules de noel",
          require: {
            field: 'giftAmount',
            value: 200,
          }
        },
        {
          key: 'bet',
          icon: 'fad fa-dice',
          description: "Parier 250 Boule de Noël",
          require: {
            field: 'betQuantity',
            value: 250,
          }
        },
        {
          icon: 'fad fa-play',
          description: "Participez a l'event de Noël",
          require: {
            field: 'play',
            value: 1,
          }
        }
      ]
    },

]